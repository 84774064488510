import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

// https://github.com/react-hook-form/react-hook-form/blob/9fd5c5632c885522fafbdc3a8bcfcbe91db684e4/examples/customInput.tsx
// https://github.com/JedWatson/react-select/discussions/4360
// https://github.com/react-hook-form/react-hook-form/blob/9fd5c5632c885522fafbdc3a8bcfcbe91db684e4/examples/customInput.tsx
// https://github.com/JedWatson/react-select/issues/3855
// https://codesandbox.io/s/react-hook-form-controller-079xx?file=/src/index.js:2519-2529
// import { ReportPatientInfo } from "@arianapharma/oncokem-services";
import { ConfigurationOption, rnaConfigurationLatestOption } from '../../Configurations';

export interface ConfigurationSelectorProps {
  options: ConfigurationOption[];
}
interface Configurable {
  configuration: ConfigurationOption;
}

export const ConfigurationSelector = (props: ConfigurationSelectorProps) => {
  const { control } = useFormContext<Configurable>();

  return (
    <FormGroup controlId="configuration">
      <FormLabel>Configuration</FormLabel>
      <Controller
        render={({ field }) => (
          <ReactSelect isClearable options={props.options} {...field} />
        )}
        defaultValue={rnaConfigurationLatestOption}
        name="configuration"
        rules={{ required: true }}
        control={control}
      />
    </FormGroup>
  );
};
