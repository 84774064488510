import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { drugCountDefaultOption, DrugCountOption, drugCountOptions } from '../../DrugCount';
import { ReportKindValues } from '../../ReportKind';

export interface DrugCountSelectorProps {
  reportKind: ReportKindValues;
}

interface DrugCountConfigurable {
  drugCount: DrugCountOption;
}

export const DrugCountSelector = (props: DrugCountSelectorProps) => {
  const { control } = useFormContext<DrugCountConfigurable>();

  console.log("DrugCountSelector", props.reportKind);
  const options = drugCountOptions.filter(
    (o) => props.reportKind !== "pdf" || o === drugCountDefaultOption
  );

  return (
    <FormGroup controlId="drugCount">
      <FormLabel>Therapy Count</FormLabel>
      <Controller
        render={({ field }) => (
          <ReactSelect isClearable options={options} {...field} />
        )}
        defaultValue={drugCountDefaultOption}
        name="drugCount"
        rules={{ required: true }}
        control={control}
      />
    </FormGroup>
  );
};
