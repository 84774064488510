import { useState } from 'react';

import { WizardMessage } from './WizardMessage';

export const useWizardMessage = () => {
  const [msg, setMsg] = useState<WizardMessage>();

  const setError = (main: string, title?: string, bottom?: string) => {
    setMsg({
      main,
      title: title || "Error",
      bottom: bottom || "Please report this to the OncoKEM team",
      variant: "danger",
    });
  };

  const appendMessage = (main: string, title?: string) => {
    setMsg((x?: WizardMessage) => ({
      title: title || x?.title || "",
      bottom: x?.bottom || "",
      main: x ? `${x.main} ${main}` : "",
      variant: x?.variant || "primary",
    }));
  };

  const setMessage = (main: string, title: string, bottom?: string) => {
    setMsg({
      main,
      title,
      bottom: bottom || "",
      variant: "light",
    });
  };

  const clearMessage = () => {
    setMsg(undefined);
  };

  return { msg, clearMessage, setMessage, appendMessage, setError };
};
