import { pick } from "lodash";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import { RequestPatientInfo, RnaRequestInput } from "@arianapharma/oncokem-services";
import { postRnaReportRequest } from "../../../API";
import {
  ConfigurationSelector,
  DrugCountSelector,
  PatientInfo,
  ReportKindSelector,
  ReportSectionsSelector,
  WizardContainer
} from "../../../components";
import {
  rnaConfigurationLatestOption,
  rnaConfigurationsOptions,
} from "../../../Configurations";
import { drugCountDefaultOption } from "../../../DrugCount";
import { RnaFormInputs } from "../../../interfaces";
import { getFileLabel, reportKindDefaultOption } from "../../../ReportKind";
import { reportSectionsDefaultOptions } from "../../../ReportSections";
import { prepareRequest } from "../PrepareRequest";

export const RnaWizard = (): JSX.Element => {
  const methods = useForm<RnaFormInputs>({
    defaultValues: {
      file: [],
      configuration: rnaConfigurationLatestOption,
      reportKind: reportKindDefaultOption,
      drugCount: drugCountDefaultOption,
      reportSections: reportSectionsDefaultOptions,
    },
  });

  const [requestId, setRequestId] = useState("");
  const [reportKind, setReportKind] = useState(reportKindDefaultOption.value);

  const handleReset = () => methods.reset();

  const handleSubmit = async (data: RnaFormInputs): Promise<void> => {
    try {
      console.log("data", data);
      const patientInfo: RequestPatientInfo = pick(data, [
        "sex",
        "metastasis",
        "location",
        "physician",
        "tumorSite",
        "age",
        "histology",
      ]);
      const { patientId } = data;
      const preparedRequest = await prepareRequest(
        patientId,
        data.file,
        patientInfo
      );
      const {
        fef,
        patientInfo: patientInfoPrepared,
        requestId,
      } = preparedRequest;
      const configId = data.configuration.value;
      const rnaReportKind = data.reportKind.value;
      const maxDrugs = rnaReportKind === "pdf" ? 10 : data.drugCount.value;
      const reportSections = data.reportSections.map((x) => x.value);

      const input: RnaRequestInput = {
        patientId,
        inputData: {
          maxDrugs,
          rnaReportKind,
          fef,
          configId,
          reportSections,
        },
        patientInfo: patientInfoPrepared,
      };
      setRequestId(requestId);
      return postRnaReportRequest(requestId, input);
    } catch (e) {
      alert("Error generating report");
      console.log(e);
    }
  };

  const reportKindValue = methods.watch("reportKind.value");
  const fileLabel = getFileLabel(reportKindValue);

  methods.watch((data) => {
    if (data) {
      const { reportKind } = data;
      if (reportKind) {
        setReportKind(reportKind.value);
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <WizardContainer
        onReset={() => handleReset()}
        onSubmit={(x: RnaFormInputs) => handleSubmit(x)}
        requestId={requestId}
        title="RNA Report"
        fileLabel={fileLabel}
      >
        <Row>
          <Col>
            <PatientInfo />
          </Col>
        </Row>
        <Row>
          <Col>
            <ConfigurationSelector options={rnaConfigurationsOptions} />
          </Col>
          <Col>
            <ReportKindSelector />
          </Col>
          <Col>
            {reportKindValue !== "pdf" && (
              <DrugCountSelector reportKind={reportKind} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>{reportKindValue !== "pdf" && <ReportSectionsSelector />}</Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </WizardContainer>
    </FormProvider>
  );
};
