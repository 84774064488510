// https://github.com/react-dropzone/react-dropzone/issues/1045
// https://github.com/react-hook-form/react-hook-form/discussions/2146
// https://www.digitalocean.com/community/tutorials/react-react-dropzone
// https://react-dropzone.js.org/#!/Styling%20Dropzone

// A drop zone is a rectangle where FEF files (*.fef) can be dropped (from the desktop) for selection

import './BatchDropFefZone.css';

import { useEffect, useState } from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }

  return "#eeeeee";
};

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export type BatchDropFefZoneProps = {
  onChange: (...event: any[]) => void;
};

export const BatchDropFefZone = (props: any) => {
  const { onChange } = props;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: true,
    accept: ".fef",
    onDrop: (files) => onChange(files),
  });

  const [hasAccepted, setHasAccepted] = useState(false);
  const [hasRejected, setHasRejected] = useState(false);

  const [showExample, setShowExample] = useState(false);

  const handleCloseExample = () => setShowExample(false);
  const handleShowExample = () => setShowExample(true);

  useEffect(() => {
    setHasAccepted(acceptedFiles && acceptedFiles.length > 0);
  }, [acceptedFiles]);

  useEffect(() => {
    setHasRejected(fileRejections && fileRejections.length > 0);
  }, [fileRejections]);

  // const hasAccepted = () => acceptedFiles && acceptedFiles.length > 0;
  // const hasRejected = () => fileRejections && fileRejections.length > 0;

  const acceptedFileItems = () => {
    const accepted = acceptedFiles || [];
    return accepted.map((file: any) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  };

  const fileRejectionItems = () => {
    const rejected = fileRejections || [];
    return rejected.map(({ file, errors }: any) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e: any) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));
  };

  const example = "# patientId 1234\nProbes	Symbol	FoldChange	green	red\netc...";
  return (
    <div>
      <Modal
        show={showExample}
        onHide={handleCloseExample}
        backdrop="static"
        keyboard={false}
        style={{ backgroundColor: "#D3D3D3" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>FEF example (Batch Mode)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            style={{ minWidth: "100%" }}
            readOnly
            defaultValue={example}
          ></textarea>
        </Modal.Body>
      </Modal>
      <Accordion>
        <Card border="info" className="mb-2">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <Card.Title>FEF file format</Card.Title>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">
                Batch Mode
              </Card.Subtitle>
              <Card.Text>
                <small>
                  In batch mode, FEF files must contain an embedded patientId
                  metadata, with a positive integer value (example: "# patientId
                  12345"). This syntax is suggested by the W3C standard for
                  Tabular Data and Metadata. The full example below shows all
                  available metadata tags. Only patientId is mandatory (case
                  senstitive). Embebbed metadata take precedence on the values
                  in the Web form.
                </small>
              </Card.Text>
              <Card.Link
                href="https://www.w3.org/TR/tabular-data-model/#embedded-metadata"
                target="_blank"
                rel="noopener noreferrer"
              >
                W3C Standard
              </Card.Link>
              <Card.Link onClick={() => handleShowExample()}>
                Full example
              </Card.Link>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

      <StyledContainer
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {!hasAccepted && (
          <div>
            <p>Drop FEF files here (only *.fef files will be accepted)</p>
          </div>
        )}
        {hasAccepted && (
          <div className="note">
            <span>Accepted FEF file</span>
            <ul>{acceptedFileItems()}</ul>
          </div>
        )}
        {hasRejected && (
          <div className="note">
            <span>Rejected files</span>
            <ul>{fileRejectionItems()}</ul>
          </div>
        )}
      </StyledContainer>
    </div>
  );
  /*
  return (
    <div className="dropzone">
      <div className="dz-zone" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="dz-message dz-clickable">
          <p>Drop FEF file here</p>
          <p>(only *.fef files will be accepted)</p>
        </div>
        {hasAccepted() && (
          <div className="note">
            <span>Accepted FEF file</span>
            <ul>{acceptedFileItems}</ul>
          </div>
        )}
        {hasRejected() && (
          <div className="note">
            <span>Rejected files</span>
            <ul>{fileRejectionItems}</ul>
          </div>
        )}
      </div>
    </div>
  );
  */
};
