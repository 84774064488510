import { v4 as uuidv4 } from 'uuid';

import { RequestPatientInfo } from '@arianapharma/oncokem-services';

// import { API } from "aws-amplify";
import { s3Upload } from '../../libs/awsLib';

export interface PreparedRequest {
  patientId: number;
  requestId: string;
  patientInfo: RequestPatientInfo;
  fef: {
    key: string;
    bucket: "";
  };
}
export const prepareRequest = async (
  patientId: number,
  files: File[],
  patientInfo: RequestPatientInfo
): Promise<PreparedRequest> => {
  if (!files || files.length === 0) {
    const msg = "Missing FEF file";
    alert(msg);
    throw new Error(msg);
  }
  if (files.length > 1) {
    const msg = "More than one FEF file";
    alert(msg);
    throw new Error(msg);
  }
  const file = files[0];
  const key = await s3Upload(file);
  const requestId = uuidv4();
  const reportDate = new Date().toDateString().split(" ").slice(1).join(" ");
  const result: PreparedRequest = {
    patientId,
    requestId,
    fef: {
      key,
      bucket: "",
    },
    patientInfo: {
      ...patientInfo,
      reportDate,
    },
  };
  return result;
};
