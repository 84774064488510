import React from 'react';
import { Table as ReactTable } from 'react-bootstrap';
import { Column, useTable } from 'react-table';

// https://codesandbox.io/s/z2v5p0w6rp?file=/src/columns.js:138-150

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-table/react-table-tests.tsx

// https://github.com/ggascoigne/react-table-example
// https://github.com/tannerlinsley/react-table/discussions/2664
// https://thewidlarzgroup.com/react-table-7/

export type { Column as TableColumn } from "react-table";

export interface TableDataRow {
  id: string;
  subRows?: TableDataRow[];
}

export interface TableProps<T extends TableDataRow> {
  columns: Array<Column<T>>;
  data: T[];
}

// @ts-ignore
export const Table = function <T extends TableDataRow>({
  columns,
  data,
}: TableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<T>({
    columns,
    data,
  });

  return (
    <ReactTable striped hover responsive {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </ReactTable>
  );
};
