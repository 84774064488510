import { RnaReportSection } from '@arianapharma/oncokem-services';

export interface ReportSectionOption {
  value: RnaReportSection;
  label: string;
}

const infoOption: ReportSectionOption = {
  value: "info",
  label: "Patient",
};

const treatmentOption: ReportSectionOption = {
  value: "treatmentScores",
  label: "Therapies",
};

const interactionOption: ReportSectionOption = {
  value: "interactionScores",
  label: "Interactions",
};

const moasOption: ReportSectionOption = {
  value: "moas",
  label: "Mechanisms of Action",
};

const approvedOption: ReportSectionOption = {
  value: "approvedTreatments",
  label: "Approved",
};

const referencesOption: ReportSectionOption = {
  value: "references",
  label: "References",
};

const genesOption: ReportSectionOption = {
  value: "genes",
  label: "Genes",
};

const metadataOption: ReportSectionOption = {
  value: "metadata",
  label: "MetaData",
};

export const reportSectionsDefaultOptions: ReportSectionOption[] = [
  infoOption,
  treatmentOption,
  interactionOption,
  metadataOption,
];

export const reportSectionsOptions: ReportSectionOption[] = [
  infoOption,
  treatmentOption,
  moasOption,
  interactionOption,
  approvedOption,
  referencesOption,
  genesOption,
  metadataOption,
];
