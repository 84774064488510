//@ts-ignore
import { fadeIn, fadeInLeft } from 'react-animations';
import styled, { keyframes } from 'styled-components';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

export const FadeInDiv3Sec = styled.div`
  animation: 3s ${fadeInAnimation};
`;

export const FadeInDiv4Sec = styled.div`
  animation: 4s ${fadeInAnimation};
`;

export const FadeInDiv5Sec = styled.div`
  animation: 5s ${fadeInAnimation};
`;

export const FadeInLeftDiv3Sec = styled.div`
  animation: 3s ${fadeInLeftAnimation};
`;
