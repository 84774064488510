import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';

import { ArchiveFormat } from '@arianapharma/oncokem-services';
import { DevTool } from '@hookform/devtools';

import { BatchFormInputs } from '../../interfaces';
import { BatchDropFefZone } from '../BatchDropFefZone/BatchDropFefZone';
import { BatchRequestProgress } from '../BatchRequestProgress/BatchRequestProgress';
import { Title } from '../Title/Title';
import { WizardMessage } from '../WizardMessage/WizardMessage';
import { WizardMessageAlert } from '../WizardMessage/WizardMessageAlert';

export interface BatchWizardContainerProps<T> {
  onReset: () => void;
  onSubmit: SubmitHandler<T>;
  fileLabel: ArchiveFormat;
  title: string;
  batchId: string;
  requestIds: string[];
  expectedDuration: number;
  msg?: WizardMessage;
  children: React.ReactNode;
}

export const BatchWizardContainer = <T extends BatchFormInputs>(
  props: BatchWizardContainerProps<T>
) => {
  const [key, setKey] = useState(0);
  const methods = useFormContext();
  console.log({ methods });
  const { handleSubmit, control } = methods;

  const { onSubmit, onReset } = props;

  const handleReset = () => {
    onReset();
    setKey(key + 1);
  };

  const hasBatchId = (): boolean => {
    return !!props.batchId;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <DevTool control={control} placement={"top-right"} />
        <Row>
          <Title>{props.title}</Title>
        </Row>
        <Row hidden={!props.msg}>
          <Col>
            <WizardMessageAlert msg={props.msg} />
          </Col>
        </Row>
        <Row>
          <Col>
            {hasBatchId() && (
              <BatchRequestProgress
                expectedDuration={props.expectedDuration}
                key={props.batchId}
                batchId={props.batchId}
                requestIds={props.requestIds}
                fileLabel={props.fileLabel}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>{props.children}</Col>
        </Row>
        <Row>
          <Col>
            <Controller
              name="file"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <BatchDropFefZone key={key} onChange={onChange} />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit">Generate</Button>
          </Col>
          <Col>
            <Button onClick={() => handleReset()}>Reset Form</Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};
