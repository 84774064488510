import './GenePanelSelector.css';

import * as path from 'path';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select, { OptionsType } from 'react-select';

import { GenePanelValidationStatus } from '@arianapharma/oncokem-services';

import { fetchGenePanels } from '../../API';

interface Folder {
  folder: string;
}

interface FolderFile extends Folder {
  file: string;
  key: string;
}

interface FolderFileVersion extends FolderFile {
  version: string;
  lastModified?: Date;
}

interface FolderLabelValue {
  label: string;
  value: Folder;
}

interface FolderFileLabelValue {
  label: string;
  value: FolderFile;
}

interface FolderFileVersionLabelValue {
  label: string;
  value: FolderFileVersion;
}

const folderLabelValue = (x: Folder): FolderLabelValue => ({
  label: x.folder,
  value: x,
});

const folderFileLabelValue = (x: FolderFile): FolderFileLabelValue => ({
  label: x.file,
  value: x,
});

const toFolders = (genePanels: GenePanelValidationStatus[]): Folder[] => {
  const foldersMap = genePanels.map((x) => x.folder);
  const foldersName: string[] = Array.from(new Set(foldersMap).values());
  foldersName.sort();
  console.log("foldersName", foldersName);
  return foldersName.map((folder) => ({ folder }));
};

const toFolderFiles = (
  genePanels: GenePanelValidationStatus[]
): FolderFile[] => {
  const keyMap = genePanels.filter((x) => x.isLatest).map((x) => x.key);
  const keys: string[] = Array.from(new Set(keyMap).values());
  return keys.map((key) => ({
    key,
    folder: path.dirname(key),
    file: path.basename(key),
  }));
};

const toFolderFileVersions = (
  genePanels: GenePanelValidationStatus[]
): FolderFileVersion[] => {
  const versions = genePanels.filter((x) => x.versionId);
  return versions.map((x) => ({
    version: x.versionId as string,
    key: x.key,
    folder: x.folder,
    lastModified: x.lastModified,
    file: path.basename(x.key),
  }));
};

const folderFileVersionLabelValue = (
  x: FolderFileVersion
): FolderFileVersionLabelValue => ({
  label: x.lastModified ? String(x.lastModified) : x.version,
  value: x,
});

const filterFiles = (
  files: FolderFileLabelValue[],
  f?: Folder
): FolderFileLabelValue[] =>
  f
    ? files.filter(
        (x) =>
          x.value.folder === f.folder ||
          (x.value.folder + "/").startsWith(f.folder)
      )
    : [];

const filterVersions = (
  versions: FolderFileVersionLabelValue[],
  f?: FolderFile
): FolderFileVersionLabelValue[] =>
  f ? versions.filter((x) => x.value.key === f.key) : [];

const selection = (
  genePanels: GenePanelValidationStatus[],
  folder?: Folder,
  file?: FolderFile,
  version?: FolderFileVersion
): GenePanelValidationStatus[] => {
  if (version) {
    return genePanels.filter(
      (x) => !!x.versionId && x.versionId === version.version
    );
  }
  if (file) {
    return genePanels.filter((x) => x.isLatest && x.key === file.key);
  }
  if (folder) {
    const folderName = folder.folder;
    return genePanels.filter(
      (x) =>
        x.isLatest &&
        (x.folder === folderName || (x.folder + "/").startsWith(folderName))
    );
  }
  return [];
};

export interface IGenePanelSelectorProps {
  show: boolean;
  close: () => void;
  selectedGenePanels: (selected: GenePanelValidationStatus[]) => void;
}

/**
 * Gene panels selector allow user to pick gene panels
 * @param props
 */
export const GenePanelSelector = (props: IGenePanelSelectorProps) => {
  // get the list of folders
  const genePanelsQuery = useQuery("genePanels", fetchGenePanels, {
    refetchOnWindowFocus: false,
  });

  const [genePanels, setGenePanels] = useState<GenePanelValidationStatus[]>([]);
  useEffect(() => {
    const data = genePanelsQuery.data || [];
    const filtered = data.filter((x) => !!x.valid);
    setGenePanels(filtered);
  }, [genePanelsQuery.data]);

  const folderOptions: FolderLabelValue[] = toFolders(genePanels).map(
    folderLabelValue
  );

  // options
  const [versionOptions, setVersionOptions] = useState<
    FolderFileVersionLabelValue[]
  >([]);
  const [fileOptions, setFileOptions] = useState<FolderFileLabelValue[]>([]);

  // used for components dislpay
  const [fileIsMulti, setFileIsMulti] = useState(true);
  const [versionIsMulti, setVersionIsMulti] = useState(false);

  // selections
  const [selectedVersions, setSelectedVersion] = useState<
    readonly FolderFileVersionLabelValue[]
  >([]);
  const [selectedFiles, setSelectedFiles] = useState<
    readonly FolderFileLabelValue[]
  >([]);
  const [selectedFolder, setSelectedFolder] = useState<
    readonly FolderLabelValue[]
  >([]);

  // updates
  const [refreshKeyFolders, setRefreshKeyFolders] = useState(0);
  const [refreshKeyFiles, setRefreshKeyFiles] = useState(0);
  const [refreshKeyVersions, setRefreshKeyVersions] = useState(0);

  // is add enabled
  const [addEnable, setAddEnabled] = useState(false);

  /**
   * Can we enable the add button
   */
  useEffect(() => {
    const enabled = selectedFolder.length === 1;
    setAddEnabled(enabled);
  }, [selectedFolder, selectedFiles, selectedVersions]);

  // force update on a change

  useEffect(() => {
    setRefreshKeyFiles((x) => x + 1);
    setFileIsMulti(fileOptions.length > 0);
  }, [fileOptions]);

  useEffect(() => {
    setRefreshKeyVersions((x) => x + 1);
    setVersionIsMulti(versionOptions.length > 0);
  }, [versionOptions]);

  useEffect(() => {
    setRefreshKeyVersions((x) => x + 1);
  }, [versionIsMulti]);

  /**
   * Default an item, or an missing value, to an array (possibly empty)
   * https://stackoverflow.com/a/56989122
   * @param t
   */
  const defaultToArray = <T,>(t?: T | readonly T[] | null): readonly T[] => {
    if (!t) {
      return [];
    }
    if (Array.isArray(t)) {
      return t;
    }
    return [t] as any;
  };

  /**
   * Default an array to an item, null if not an array with a single item
   * https://stackoverflow.com/a/56989122
   * @param t
   */
  const defaultToItem = <T,>(t?: T | readonly T[] | null): T | null => {
    if (!t) {
      return null;
    }
    if (Array.isArray(t)) {
      return t.length === 1 ? t[0] : null;
    }
    return t as any;
  };

  /**
   * Selected folder changed, update other options
   * @param selectedOptions
   */
  const handleOnChangeFolder = (
    selectedOptions?: FolderLabelValue | FolderLabelValue[] | null | undefined
  ) => {
    const selected: readonly FolderLabelValue[] = defaultToArray<FolderLabelValue>(
      selectedOptions
    );
    setSelectedFolder(selected);
    setVersionOptions([]);
    setSelectedVersion([]);

    const files =
      selected.length === 1
        ? filterFiles(
            toFolderFiles(genePanels).map(folderFileLabelValue),
            selected[0].value
          )
        : [];

    console.log("files", files);
    setFileOptions(files);
  };

  /**
   * The selected (files) changed, update the version options
   * @param selectedOptions
   */
  const handleOnChangeFiles = (
    selectedOptions:
      | FolderFileLabelValue
      | OptionsType<FolderFileLabelValue>
      | null
  ) => {
    const selected: readonly FolderFileLabelValue[] = defaultToArray<FolderFileLabelValue>(
      selectedOptions
    );
    // set the selected files
    setSelectedFiles(selected);
    setSelectedVersion([]);
    setVersionOptions(
      selected.length === 1
        ? filterVersions(
            toFolderFileVersions(genePanels).map(folderFileVersionLabelValue),
            selected[0].value
          )
        : []
    );
  };

  /**
   * Selected version changed
   * @param selectedOptions
   */
  const handleOnChangeVersions = (
    selectedOptions?:
      | FolderFileVersionLabelValue
      | FolderFileVersionLabelValue[]
      | null
      | undefined
  ) => {
    const selected = defaultToArray<FolderFileVersionLabelValue>(
      selectedOptions
    );
    console.log("selected", selected);
    setSelectedVersion(selected);
  };

  /**
   * Helper to filter out null or undefined values
   * @param value
   */
  /*
  const notEmpty = <TValue,>(
    value: TValue | null | undefined
  ): value is TValue => {
    return value !== null && value !== undefined;
  };
  */

  /**
   * Usr click on add button, find s3 files that maps
   * the the selected options
   */
  const addGenePanelsEvent = () => {
    const folder = defaultToItem(selectedFolder)?.value;
    const file = defaultToItem(selectedFiles)?.value;
    const version = defaultToItem(selectedVersions)?.value;

    // clear files selection
    setSelectedFiles([]);
    setSelectedVersion([]);
    // increment the key to force a refresh
    setRefreshKeyFolders(refreshKeyFolders + 1);

    const selected = selection(genePanels, folder, file, version);
    console.log("selected", selected);
    // forward the selection to the main app state
    props.selectedGenePanels(selected);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.close()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Gene Panels</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container>
            <Row className="mt-3">
              <Col>
                <Select
                  key={"-folders"}
                  onChange={(x) => handleOnChangeFolder(x)}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  options={folderOptions}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Select
                  key={refreshKeyFiles + "-files"}
                  onChange={(x) => handleOnChangeFiles(x)}
                  closeMenuOnSelect={false}
                  isMulti={fileIsMulti}
                  options={fileOptions}
                />
              </Col>
            </Row>
            {versionIsMulti && (
              <Row className="mt-3">
                <Col>
                  <Select
                    key={refreshKeyVersions + "-versions"}
                    onChange={(x) => handleOnChangeVersions(x)}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    options={versionOptions}
                  />
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.close()}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={!addEnable}
          onClick={() => addGenePanelsEvent()}
        >
          Add Panel(s)
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
