import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import { Action, ThunkAction } from '@reduxjs/toolkit';

import globalReducer from './slices/GlobalSlice';

const rootReducer = combineReducers({
  global: globalReducer,
});

const middlewareEnhancer = applyMiddleware(thunkMiddleware);
const composeEnhancers = composeWithDevTools(middlewareEnhancer);

export const store = createStore(rootReducer, undefined, composeEnhancers);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
