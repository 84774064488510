import './App.css';

import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';

import { AppContext } from './libs/contextLib';
import Routes from './Routes';

// https://serverless-stack.com/chapters/load-the-state-from-the-session.html

function App() {
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      console.log("error");
      console.log(e);

      if (e !== "No current user") {
        alert("Logged out. Please, sign-in");
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    history.push("/login");
  }

  // @ts-ignore
  return (
    <div className="App container-fluid">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand>
          <LinkContainer to="/">
            <NavItem>OncoKEM</NavItem>
          </LinkContainer>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav>
              {isAuthenticated ? (
                <>
                  <Nav.Item>
                    <Nav.Link href="/reports" title="Reports">
                      Reports
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link href="/genepanels" title="Gene Panels">
                      Gene Panels
                    </Nav.Link>
                  </Nav.Item>

                  <NavDropdown title="Wizards" id="nav-dropdown-waizards">
                    <NavDropdown.Item href="/wizards/rna">RNA</NavDropdown.Item>
                    <NavDropdown.Item href="/wizards/batch-rna">
                      RNA Batch
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/wizards/dna">
                      DNA (Placeholder)
                    </NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item href="/wizards/genepanels">
                      Gene Panels
                    </NavDropdown.Item>

                    <NavDropdown.Item href="/wizards/genesxlsx">
                      Genes Expression (XLSX)
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* 
                  <Form inline>
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> 
                  */}
                  <Nav.Item onClick={handleLogout}>
                    <Nav.Link title="Logout">Logout</Nav.Link>
                  </Nav.Item>
                </>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Routes />
      </AppContext.Provider>
    </div>
  );
}

export default App;
