import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
// import { RnaReportSection } from "@arianapharma/oncokem-services";
import ReactSelect from 'react-select';

import {
    ReportSectionOption, reportSectionsDefaultOptions, reportSectionsOptions
} from '../../ReportSections';

export interface ReportSectionsSelectorProps {}

interface ReportSectionsConfigurable {
  reportSections: ReportSectionOption[];
}

export const ReportSectionsSelector = (props: ReportSectionsSelectorProps) => {
  const { control } = useFormContext<ReportSectionsConfigurable>();

  return (
    <FormGroup controlId="reportSections">
      <FormLabel>Report Sections</FormLabel>

      <Controller
        render={({ field }) => (
          <ReactSelect
            isClearable
            isMulti
            options={reportSectionsOptions}
            {...field}
          />
        )}
        defaultValue={reportSectionsDefaultOptions}
        name="reportSections"
        rules={{ required: true }}
        control={control}
      />
    </FormGroup>
  );
};
