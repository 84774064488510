export type RnaConfigurationsId = 1 | 2 | 3 | 4 | 5;

export interface RnaConfigurationOption {
  value: RnaConfigurationsId;
  label: string;
}
export type ConfigurationOption = RnaConfigurationOption; // | DnaConfigurationOption;

export const rnaConfigurationLatestOption: RnaConfigurationOption = {
  value: 5,
  label: "Config 5: RNA - KB v9 (latest)",
};

export const rnaConfigurationsOptions: RnaConfigurationOption[] = [
  {
    value: 1,
    label: "Config 1: RNA - KB v5",
  },
  {
    value: 2,
    label: "Config 2: RNA - KB v6",
  },
  {
    value: 3,
    label: "Config 3: RNA - KB v7",
  },
  {
    value: 4,
    label: "Config 4: RNA - KB v8",
  },
  rnaConfigurationLatestOption,
];
export const configurationsOptions: ConfigurationOption[] = [
  ...rnaConfigurationsOptions,
];
