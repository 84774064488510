import './Timer.css';

import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

export interface TimerProps {
  duration: number;
  key: string;
}

export const Timer = (props: TimerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTime, setCurrentTime] = React.useState(0);
  const [isLate, setIsLate] = React.useState(false);

  const renderTime = (remainingTime?: number) => {
    if (remainingTime === undefined) {
      return <div></div>;
    }
    setCurrentTime(remainingTime);
    if (remainingTime === 0) {
      setIsLate(true);
      return (
        <div className="timer">
          <div className="late">A bit late..</div>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  // console.log(currentTime);

  return (
    <div>
      <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying
          strokeWidth={5}
          size={100}
          key={props.key}
          duration={props.duration}
          colors={[
            ["#004777", 0.33],
            ["#F7B801", 0.33],
            ["#A30000", 0.33],
          ]}
          onComplete={() => [true, 1000]}
        >
          {(x) => renderTime(x.remainingTime)}
        </CountdownCircleTimer>
      </div>
      {isLate && (
        <div>
          <p className="info">
            Running a bit late.. Please wait some more. Take a deep breath,
            relax, everything will go just fine.
          </p>
        </div>
      )}
    </div>
  );
};
