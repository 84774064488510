import "./Reports.css";

import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";

import { WizardReport } from "@arianapharma/oncokem-services";

import { FadeInDiv3Sec } from "../../Animations";
import { Table, TableColumn } from "../../components";
import { useAppContext } from "../../libs/contextLib";
import { onError } from "../../libs/errorLib";
import { loadReports } from "../../API";

interface ReportsTableRow extends WizardReport {
  id: string;
  patientId: number;
  fefKey: string;
  bucket: string;
  reportKey: string;
  kind: string;
}

type ReportsTableColumn = TableColumn<ReportsTableRow>;

export default function Home() {
  console.log("Reports.tsx Home");
  const [reports, setReports] = useState([] as ReportsTableRow[]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const columns: Array<ReportsTableColumn> = useMemo(
    () => [
      {
        Header: "Report Id",
        accessor: "reportId",
      },
      {
        Header: "Bucket",
        accessor: "bucket",
      },
      {
        Header: "Patient Id",
        accessor: "patientId",
      },
      {
        Header: "FEF Key",
        accessor: "fefKey",
      },
      {
        Header: "Report Key",
        accessor: "reportKey",
      },
      {
        Header: "Kind",
        accessor: "kind",
      },
      /*       {
        Header: "Created At",
        accessor: "createdAt",
        Cell: (props: any) => {
          const value: string =
            props && props.value ? new Date(props.value).toDateString() : "";
          return <span>{value}</span>;
        },
      }, */
    ],
    []
  );

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const reports = (await loadReports()).map((x: WizardReport) => ({
          ...x,
          id: x.reportId,
        }));
        setReports(reports);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function renderLander() {
    return (
      <div className="lander">
        <h1>Reports</h1>
      </div>
    );
  }

  function renderTable() {
    return <Table columns={columns} data={reports} />;
  }

  function renderReports() {
    return (
      <FadeInDiv3Sec>
        <div className="Reports" style={{ padding: "10px", margin: "10px" }}>
          <Container>{!isLoading && renderTable()}</Container>
        </div>
      </FadeInDiv3Sec>
    );
  }

  return (
    <div>
      <div className="Home">
        {isAuthenticated ? renderReports() : renderLander()}
      </div>
    </div>
  );
}
