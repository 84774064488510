import React from 'react';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';

import { reportKindDefaultOption, ReportKindOption, reportKindOptions } from '../../ReportKind';

export interface ReportKindSelectorProps {}

interface ReportKindConfigurable {
  reportKind: ReportKindOption;
}

export const ReportKindSelector = (props: ReportKindSelectorProps) => {
  const { control } = useFormContext<ReportKindConfigurable>();

  return (
    <FormGroup controlId="reportKind">
      <FormLabel>Report Kind</FormLabel>

      <Controller
        render={({ field }) => (
          <ReactSelect isClearable options={reportKindOptions} {...field} />
        )}
        defaultValue={reportKindDefaultOption}
        name="reportKind"
        rules={{ required: true }}
        control={control}
      />
    </FormGroup>
  );
};
