import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import { UpdateRequestInfo } from "@arianapharma/oncokem-services";

import FileLink from "../FileLink/FileLink";
import { useInterval } from "./useInterval";
import { getRequestInfo } from "../../API";

export interface RequestProgressProps {
  requestId: string;
  fileLabel: "XLSX" | "PDF" | "JSON";
}

type AlertVariant = "primary" | "danger" | "warning" | "success" | "secondary";

interface RequestValues {
  requestValues?: unknown;
  error?: string;
  reportKey?: string;
}

interface Progress extends RequestValues {
  alertVariant: AlertVariant;
  isLoading: boolean;
}

const defaultProgress: Progress = {
  alertVariant: "primary",
  isLoading: true,
};

const getIsLoading = (requestStatus = ""): boolean => {
  switch (requestStatus) {
    case "started":
    case "in-progress":
      return true;
  }
  return false;
};

const getVariant = (requestStatus = ""): AlertVariant => {
  switch (requestStatus) {
    case "done":
      return "success";
    case "error":
      return "danger";
    case "in-progress":
    case "started":
      return "primary";
  }
  return "warning";
};

const requestValues = (info: UpdateRequestInfo): RequestValues => {
  if (!info.requestValues) {
    return {};
  }
  return JSON.parse(info.requestValues);
};

const getProgress = (info: UpdateRequestInfo): Progress => {
  if (info) {
    return {
      isLoading: getIsLoading(info.requestStatus),
      alertVariant: getVariant(info.requestStatus),
      ...requestValues(info),
    };
  } else {
    return {
      isLoading: false,
      alertVariant: "secondary",
    };
  }
};

export const RequestProgress = (props: RequestProgressProps) => {
  const defaultInfo: UpdateRequestInfo = {
    requestId: props.requestId,
    requestStatus: "started",
  };

  const [info, setInfo] = useState<UpdateRequestInfo>(defaultInfo);
  const [progress, setProgress] = useState<Progress>(defaultProgress);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [delay, _setDelay] = useState(1000 * 10);

  useInterval(
    async () => {
      if (props.requestId) {
        const info: UpdateRequestInfo = await getRequestInfo(props.requestId);
        const progress: Progress = getProgress(info);
        setInfo(info);
        setProgress(progress);
        console.log("info", info);
        console.log("progress", progress);
      }
    },
    progress.isLoading ? delay : null
  );

  const renderTaskProgress = () => {
    return (
      <div>
        {props.requestId && (
          <Alert variant={progress.alertVariant}>
            <Alert.Heading>Request {info.requestStatus}</Alert.Heading>

            <hr />

            <p>Request Id: {props.requestId}</p>

            <p>Request Step: {info.requestStep}</p>

            <p className="mb-0">{progress.error}</p>
            {!!progress.reportKey && (
              <FileLink content="" fileKey={progress.reportKey}>
                [{props.fileLabel}]
              </FileLink>
            )}
          </Alert>
        )}
      </div>
    );
  };

  return (
    <div key={props.requestId}>
      {props.requestId && (
        <>
          <div>{renderTaskProgress()}</div>
        </>
      )}
    </div>
  );
};
