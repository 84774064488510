const defaultRegion = "eu-west-3";
const defaultCognitoRegion = "eu-west-3";

const ttrxRegion = "eu-west-1";
const ttrxCognitoRegion = "eu-west-1";

const uploadBucketTemplate = "oncokem-upload";

export interface CognitoEnv {
  REGION: string;
  USER_POOL_ID: string;
  APP_CLIENT_ID: string;
  IDENTITY_POOL_ID: string;
}

export interface S3Env {
  REGION: string;
  BUCKET: string;
}
export interface StageEnv {
  cognito: CognitoEnv;
}

export interface GatewayEnv {
  REGION: string;
  URL: string;
}

export interface StageEnv {
  cognito: CognitoEnv;
  s3: S3Env;
  gateway: GatewayEnv;
  MAX_ATTACHMENT_SIZE: number;
}

const cognitoDev: CognitoEnv = {
  REGION: defaultCognitoRegion,
  USER_POOL_ID: "eu-west-3_jHNq0Q3DE",
  APP_CLIENT_ID: "18b3h8h3glotee9r91rrn1esqk",
  IDENTITY_POOL_ID: "eu-west-3:a5597b63-722e-4a0b-b9cb-4913bdb782af",
};

const cognitoStage: CognitoEnv = {
  REGION: defaultCognitoRegion,
  USER_POOL_ID: "eu-west-3_Tkf3FiKJ5",
  APP_CLIENT_ID: "5b6ssnu7jjlprahton24a9v6sg",
  IDENTITY_POOL_ID: "eu-west-3:5f7f4d9c-ba5d-4340-8f25-a02f67e604fc",
};

const cognitoProd: CognitoEnv = {
  REGION: defaultCognitoRegion,
  USER_POOL_ID: "eu-west-3_eqAKnBzUO",
  APP_CLIENT_ID: "1151p6v6pj6p433fsdgs2gh1c6",
  IDENTITY_POOL_ID: "eu-west-3:f54e946f-8800-4ce1-bcf9-d6f208e25633",
};

const cognitoTtrx: CognitoEnv = {
  REGION: ttrxCognitoRegion,
  USER_POOL_ID: "eu-west-1_tYuUTZiUl",
  APP_CLIENT_ID: "5pgbf9jj1odclp1c1kf12t0utv",
  IDENTITY_POOL_ID: "eu-west-1:7cd3c241-de49-4fe6-acab-cb14db8ced74",
};

const getCognitoEnv = (stage: string): CognitoEnv => {
  switch (stage) {
    case "dev":
      return cognitoDev;
    case "stage":
      return cognitoStage;
    case "prod":
      return cognitoProd;
    case "ttrx":
      return cognitoTtrx;
    default:
      throw new Error(`Unsupported stage for cognito ${stage}`);
  }
};

const getUrl = (stage: string): string => {
  switch (stage) {
    case "dev":
    case "stage":
    case "prod":
      return `https://api-${stage}.oncokem.net`;
    case "ttrx":
      return `https://api-${stage}.omicsdiag.com`;
    default:
      throw new Error(`Unsupported stage for Url ${stage}`);
  }
};

const getRegion = (stage: string): string => {
  switch (stage) {
    case "dev":
    case "stage":
    case "prod":
      return defaultRegion;
    case "ttrx":
      return ttrxRegion;
    default:
      throw new Error(`Unsupported stage for region ${stage}`);
  }
};


export const getConfigEnv = (): StageEnv => {
  const REACT_APP_STAGE = process.env.REACT_APP_STAGE;
  console.log("REACT_APP_STAGE", REACT_APP_STAGE);
  const stage = String(REACT_APP_STAGE).toLowerCase();
  const gatewayUrl = getUrl(stage);
  const region = getRegion(stage);
  const stageEnv = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: region,
      BUCKET: `${uploadBucketTemplate}-${stage}`,
    },
    gateway: {
      REGION: region,
      URL: gatewayUrl,
    },
    cognito: getCognitoEnv(stage),
  };
  return stageEnv;
};
