import { pick } from "lodash";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import {
  GenesXlsxRequestInput,
  RequestPatientInfo,
} from "@arianapharma/oncokem-services";

import { PatientInfo, WizardContainer } from "../../../components";
import { GenesXlsxFormInputs } from "../../../interfaces";
import { prepareRequest } from "../PrepareRequest";
import { postGenesXlsxReport } from "../../../API";

export const GenesXlsxWizard = () => {
  const methods = useForm<GenesXlsxFormInputs>({
    defaultValues: {
      file: [],
      theme: "TableStyleMedium5",
      orientation: "landscape",
      paperSize: "A4",
    },
  });

  const handleReset = () => methods.reset();

  const handleSubmit = async (data: GenesXlsxFormInputs): Promise<void> => {
    console.log(data);
    try {
      const { patientId, file } = data;
      const patientInfo: RequestPatientInfo = pick(data, [
        "sex",
        "metastasis",
        "location",
        "physician",
        "tumorSite",
        "age",
        "histology",
      ]);

      const preparedRequest = await prepareRequest(
        patientId,
        file,
        patientInfo
      );
      const {
        fef,
        patientInfo: patientInfoPrepared,
        requestId,
      } = preparedRequest;
      const input: GenesXlsxRequestInput = {
        patientId,
        inputData: {
          fef,
          xlsxOptions: {
            theme: data.theme,
            orientation: data.orientation,
            paperSize: data.paperSize,
          },
        },
        patientInfo: patientInfoPrepared,
      };
      setRequestId(requestId);
      return postGenesXlsxReport(requestId, input);
    } catch (e) {
      alert("Error generating xlsx");
      console.log(e);
    }
  };

  const [requestId, setRequestId] = useState("");

  return (
    <FormProvider {...methods}>
      <WizardContainer
        fileLabel="XLSX"
        requestId={requestId}
        title="Genes XLSX"
        onSubmit={handleSubmit}
        onReset={() => handleReset()}
      >
        <Row>
          <Col>
            <PatientInfo />
          </Col>
        </Row>
      </WizardContainer>
    </FormProvider>
  );
};
