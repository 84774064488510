import "./FileLink.css";

import * as FileSaver from "file-saver";
import React, { EffectCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getSignedUrl } from "../../API";

// @ts-ignore
export default function FileLink({
  disabled = false,
  content = "file",
  fileKey = "",
  className = "",
  ...props
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_url, setUrl] = useState("");
  const [id, setId] = useState("");

  const fetch: EffectCallback = (): void => {
    if (!id) {
      return;
    }

    getSignedUrl(id)
      .then((url) => {
        url = typeof url === "string" ? url : "";
        setUrl(url);
        if (url) {
          FileSaver.saveAs(url);
        }
        setId("");
      })
      .catch((e) => {
        setUrl("");
        setId("");
      });
  };

  useEffect(fetch, [id]);

  function handleClick(e: any) {
    // const id = btoa(fileKey);
    setId(fileKey);
  }

  return (
    <Button disabled={disabled} {...props} onClick={handleClick}>
      {props.children}
    </Button>
  );
}
