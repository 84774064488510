import { API } from "aws-amplify";

// import { IGenePanel } from "./interfaces/IGenePanel";
import {
  ArchiveFormat,
  GenePanel,
  GenePanelValidationStatus,
  RnaRequestInput,
  WizardReportList,
  WizardReport,
  UpdateRequestInfo,
  GenePanelsRequestInput,
  GenesXlsxRequestInput,
} from "@arianapharma/oncokem-services";

const endpoint = "oncokem";

export const getSignedUrl = async (key: string): Promise<string> => {
  const id = btoa(key);
  const filesRes = await API.get(endpoint, "/files/" + id, {});
  const url = filesRes && typeof filesRes.url === "string" ? filesRes.url : "";
  if (!url) {
    throw new Error("Missing url");
  }
  return url;
};

export const postGenesReport = async (
  requestId: string,
  input: GenePanelsRequestInput
) => {
  return API.post(endpoint, "/reports/genes", {
    body: { requestId, input },
  });
};

export const postGenesXlsxReport = async (
  requestId: string,
  input: GenesXlsxRequestInput
) => {
  const path = `/reports/genesxlsx/${requestId}`;
  const params = {
    body: { requestId, input },
  };
  console.log("postGenesXlsxReport using path", path);
  return API.post(endpoint, path, params);
};

export async function loadReports(): Promise<WizardReport[]> {
  try {
    console.log("loading reports");
    const result: WizardReport[] = await API.get(endpoint, "/reports", {});
    return result;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export const fetchReports = async () => {
  const result = await API.get(endpoint, "/reports/", {});
  return result as WizardReportList;
};

export const fetchGenePanels = async (): Promise<
  GenePanelValidationStatus[]
> => {
  console.log("fetchGenePanels");
  const result = await API.get(endpoint, "/genepanels/", {});
  console.log(result);
  return result.genePanels as GenePanelValidationStatus[];
};

export const checkGenePanels = async (): Promise<string> => {
  console.log("checkGenePanels");
  const result = await API.post(endpoint, "/genepanels/", {});
  console.log("checkGenePanels result", result);
  return result.check as string;
};

export const archive = async (
  batchId: string,
  kind: ArchiveFormat,
  now: string
): Promise<string> => {
  try {
    console.log("archive " + batchId);
    const path = `/archive/${kind}/${now}/${encodeURIComponent(batchId)}`;
    const data = await API.post(endpoint, path, {});
    console.log("archive API result", data);
    if (data.file && data.file.key) {
      return data.file.key as string;
    }
    throw new Error("Missing key after archive");
    // const blob = b64toBlob(data, zipType);
  } catch (e) {
    console.log("archive exception");
    console.log(e);
    throw e;
  }
};

export const getRequestInfo = async (
  requestId: string
): Promise<UpdateRequestInfo> => {
  const result = await API.get(endpoint, "/requests/" + requestId, {});
  return result as UpdateRequestInfo;
};

export const postRnaReportRequest = async (
  requestId: string,
  input: RnaRequestInput
): Promise<any> => {
  const path = `/reports/rna/${requestId}`;
  const params = {
    body: input,
  };
  console.log(`posting rna request using path ${path}`);
  console.log(input);
  const result = await API.post(endpoint, path, params);
  console.log("posting request " + requestId + " result", result);
  return result;
};

export const fetchGenePanel = async (
  Key: string,
  VersionId?: string
): Promise<GenePanel> => {
  const encodedKey = encodeURIComponent(Key);
  const url = VersionId
    ? `/genepanels/${encodedKey}/${encodeURIComponent(VersionId)}`
    : `/genepanels/${encodedKey}`;
  const result = await API.get(endpoint, url, {});
  console.log("fetchGenePanel", result?.genePanel?.name);
  return result as GenePanel;
};
