import './GenePanelChart.css';

// import { GenePanelParam } from "@arianapharma/oncokem-services";
import React from 'react';
import {
    Accordion, Button, ButtonGroup, Card, Col, Container, Form, FormControl, FormGroup, FormLabel,
    Row, Tab, Tabs
} from 'react-bootstrap';
import { useFormContext, UseFormRegister } from 'react-hook-form';
import ReactJson from 'react-json-view';

import { ChartParam, GenePanelsFormInputs } from '../../interfaces';

export interface GenePanelChartProps {
  index: number;
  register: UseFormRegister<GenePanelsFormInputs>;
  chart: ChartParam;
  removeChart: (chart: ChartParam) => void;
  dragHandleProps: any;
}
export const GenePanelChart = (props: GenePanelChartProps) => {
  const { watch } = useFormContext<GenePanelsFormInputs>();

  const { chart, register, index } = props;
  const selected = `charts.${index}.selected` as const;
  const name = `charts.${index}.name` as const;
  const description = `charts.${index}.description` as const;
  const comments = `charts.${index}.comments` as const;
  const top = `charts.${index}.top` as const;
  const symbolsCount = `charts.${index}.symbolsCount` as const;
  const log2 = `charts.${index}.log2` as const;

  const removeChartEvent = () => props.removeChart(chart);

  const title = () =>
    `${watch(name)} (${watch(symbolsCount)} symbol${
      watch(symbolsCount) > 0 ? "s" : ""
    })`;

  return (
    <Accordion key={chart.id}>
      <Card border="primary">
        <Card.Header {...props.dragHandleProps}>
          <div className="d-flex">
            <div className="p-2">
              <Form.Check
                className="checkbox-2x"
                type="checkbox"
                label=""
                id={chart.id + "-selected"}
                {...register(selected)}
                defaultChecked={chart.selected}
              />
            </div>
            <div className="p-2">
              <Accordion.Toggle as={Button} variant="link" eventKey={chart.id}>
                {title()}
              </Accordion.Toggle>
            </div>
            <div className="ml-auto p-2">
              <ButtonGroup>
                <Button variant="danger" onClick={() => removeChartEvent()}>
                  &#x02A2F;
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey={chart.id}>
          <Card.Body>
            <div className="gene-panel-composition">
              <Tabs defaultActiveKey="Summary" id="genepanels-tabs">
                <Tab eventKey="Summary" title="Summary">
                  <Container fluid style={{ padding: "30px" }}>
                    <Row className="mt-3">
                      <Col>
                        <FormGroup controlId="chartName">
                          <FormLabel>Name (Visible in the report)</FormLabel>
                          <FormControl
                            {...register(name)}
                            defaultValue={chart.name}
                            type="string"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup controlId="maxGene">
                          <FormLabel>Max Gene Count (Chart only)</FormLabel>
                          <FormControl
                            {...register(top)}
                            type="number"
                            defaultValue={chart.top}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup controlId="chartSymbolsCount">
                          <FormLabel>Symbols Count</FormLabel>
                          <FormControl
                            readOnly
                            type="number"
                            {...register(symbolsCount)}
                            defaultValue={chart.symbolsCount}
                          />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup controlId="panelLog2">
                          <FormLabel>Scale</FormLabel>
                          <Form.Check
                            type="checkbox"
                            label="Log2 scale"
                            id={chart.id + "-log2"}
                            {...register(log2)}
                            defaultChecked={chart.log2}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup controlId="chartDescription">
                          <FormLabel>
                            Description (Visible in the report)
                          </FormLabel>
                          <FormControl
                            type="string"
                            {...register(description)}
                            defaultValue={chart.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <FormGroup controlId="comments">
                          <FormLabel>
                            Comments (Not visible in the report)
                          </FormLabel>
                          <FormControl
                            readOnly={true}
                            type="string"
                            {...register(comments)}
                            defaultValue={chart.comments}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                </Tab>

                <Tab eventKey="TreeView" title="TreeView">
                  <Container style={{ padding: "30px" }}>
                    <Row>
                      <Col>
                        <ReactJson src={chart.genePanel} />
                      </Col>
                    </Row>
                  </Container>
                </Tab>
              </Tabs>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
