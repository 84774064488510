export type ReportKindValues = "pdf" | "xlsx" | "json";

export const getFileLabel = (value: ReportKindValues): "XLSX" | "PDF" | "JSON" => {
  switch (value) {
    case "json":
      return "JSON";
    case "xlsx":
      return "XLSX";
    default:
      return "PDF";
  }
};

export interface ReportKindOption {
  value: ReportKindValues;
  label: string;
}

export const reportKindDefaultOption: ReportKindOption = {
  value: "pdf",
  label: "PDF (Standard Client report)",
};

export const reportKindOptions: ReportKindOption[] = [
  reportKindDefaultOption,
  {
    value: "xlsx",
    label: "XLSX (Internal use only, not to be disseminated)",
  },
  {
    value: "json",
    label: "JSON (Internal use only, not to be disseminated)",
  },
];
