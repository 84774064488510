import {
    GenePanelValidationStatus, WizardReport, WizardReportList
} from '@arianapharma/oncokem-services';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchGenePanels as fetchPanels, fetchReports } from '../API';
import { AppThunk, RootState } from '../store';

export interface GlobalState {
  reports: WizardReport[];
  genePanels: GenePanelValidationStatus[];
}

const initialGlobalState: GlobalState = {
  reports: [],
  genePanels: [],
};

export const globalSlice = createSlice({
  name: "wizard",
  initialState: initialGlobalState,
  reducers: {
    setReports: (state, action: PayloadAction<WizardReportList>) => {
      state.reports = action.payload.reports;
    },
    setGenePanels: (
      state,
      action: PayloadAction<GenePanelValidationStatus[]>
    ) => {
      state.genePanels = action.payload;
    },
  },
});

export const { setReports, setGenePanels } = globalSlice.actions;

export const selectGlobal = (state: RootState): GlobalState => state.global;

export default globalSlice.reducer;

export const fetchArianaReports = (): AppThunk => async (dispatch) => {
  try {
    const reports = await fetchReports();
    dispatch(setReports(reports));
  } catch (err) {
    console.log(err);
  }
};

export const fetchGenePanels = (): AppThunk => async (dispatch) => {
  try {
    const genePanels: GenePanelValidationStatus[] = await fetchPanels();
    dispatch(setGenePanels(genePanels));
  } catch (err) {
    console.log(err);
  }
};
