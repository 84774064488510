import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute/UnauthenticatedRoute';
import GenePanelsStatus from './containers/GenePanels/GenePanels';
import Home from './containers/Home/Home';
import Login from './containers/Login/Login';
import NotFound from './containers/NotFound/NotFound';
import Report from './containers/Reports/Reports';
import { BatchRnaWizard } from './containers/Wizards/BatchRnaWizard/BatchRnaWizard';
import { DnaWizard } from './containers/Wizards/DnaWizard/DnaWizard';
import { GenePanelsWizard } from './containers/Wizards/GenePanelsWizard/GenePanelsWizard';
import { GenesXlsxWizard } from './containers/Wizards/GenesXlsxWizard/GenesXlsxWizard';
import { RnaWizard } from './containers/Wizards/RnaWizard/RnaWizard';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/wizards/genepanels">
        <GenePanelsWizard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/wizards/genesxlsx">
        <GenesXlsxWizard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/wizards/rna">
        <RnaWizard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/wizards/batch-rna">
        <BatchRnaWizard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/wizards/dna">
        <DnaWizard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/reports">
        <Report />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/genepanels">
        <GenePanelsStatus />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/reports/:id">
        <Report />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
