import React from 'react';

import { FadeInLeftDiv3Sec } from '../../Animations';

export const Title = (props: { children: React.ReactNode }) => {
  const { children } = props;
  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <FadeInLeftDiv3Sec>
          <h3 className="mb-3">{children}</h3>
        </FadeInLeftDiv3Sec>
      </div>
      <hr className="w-100" />
    </div>
  );
};
