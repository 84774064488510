import * as nanoid from 'nanoid';

export const reportIdAlphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const reportIdSize = 12;

/**
 * Generate a new  id
 */
export const makeId = (): string => {
  const generateReportId = nanoid.customAlphabet(
    reportIdAlphabet,
    reportIdSize
  );
  return generateReportId();
};
