// https://github.com/react-dropzone/react-dropzone/issues/1045
// https://github.com/react-hook-form/react-hook-form/discussions/2146
// https://www.digitalocean.com/community/tutorials/react-react-dropzone
// https://react-dropzone.js.org/#!/Styling%20Dropzone

// A drop zone is a rectangle where FEF files (*.fef) can be dropped (from the desktop) for selection

import './DropFefZone.css';

import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

/*
interface GetColorProps {
  isDragAccept: boolean;
  isDragReject: boolean;
  isDragActive: boolean;
}
*/

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }

  return "#eeeeee";
};

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export type DropFefZoneProps = {
  onChange: (...event: any[]) => void;
};

export const DropFefZone = (props: any) => {
  const { onChange } = props;

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple: false,
    accept: ".fef",
    onDrop: (files) => onChange(files),
  });

  const [hasAccepted, setHasAccepted] = useState(false);
  const [hasRejected, setHasRejected] = useState(false);

  useEffect(() => {
    setHasAccepted(acceptedFiles && acceptedFiles.length > 0);
  }, [acceptedFiles]);

  useEffect(() => {
    setHasRejected(fileRejections && fileRejections.length > 0);
  }, [fileRejections]);

  // const hasAccepted = () => acceptedFiles && acceptedFiles.length > 0;
  // const hasRejected = () => fileRejections && fileRejections.length > 0;

  const acceptedFileItems = () => {
    const accepted = acceptedFiles || [];
    return accepted.map((file: any) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  };

  const fileRejectionItems = () => {
    const rejected = fileRejections || [];
    return rejected.map(({ file, errors }: any) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e: any) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));
  };

  return (
    <div>
      <StyledContainer
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        {!hasAccepted && (
          <div>
            <p>Drop FEF file here (only *.fef files will be accepted)</p>
          </div>
        )}
        {hasAccepted && (
          <div className="note">
            <span>Accepted FEF file</span>
            <ul>{acceptedFileItems()}</ul>
          </div>
        )}
        {hasRejected && (
          <div className="note">
            <span>Rejected files</span>
            <ul>{fileRejectionItems()}</ul>
          </div>
        )}
      </StyledContainer>
    </div>
  );
  /*
  return (
    <div className="dropzone">
      <div className="dz-zone" {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="dz-message dz-clickable">
          <p>Drop FEF file here</p>
          <p>(only *.fef files will be accepted)</p>
        </div>
        {hasAccepted() && (
          <div className="note">
            <span>Accepted FEF file</span>
            <ul>{acceptedFileItems}</ul>
          </div>
        )}
        {hasRejected() && (
          <div className="note">
            <span>Rejected files</span>
            <ul>{fileRejectionItems}</ul>
          </div>
        )}
      </div>
    </div>
  );
  */
};
