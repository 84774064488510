import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm, useWatch } from "react-hook-form";

import {
  ReportFile,
  RequestPatientInfo,
  RnaRequestInput,
  RnaReportSection,
} from "@arianapharma/oncokem-services";

import { postRnaReportRequest } from "../../../API";
import {
  BatchWizardContainer,
  ConfigurationSelector,
  DrugCountSelector,
  ReportKindSelector,
  ReportSectionsSelector,
} from "../../../components";
import { useWizardMessage } from "../../../components/WizardMessage/useWizardMessage";
import {
  rnaConfigurationLatestOption,
  RnaConfigurationsId,
  rnaConfigurationsOptions,
} from "../../../Configurations";
import { drugCountDefaultOption } from "../../../DrugCount";
import { BatchRnaFormInputs } from "../../../interfaces";
import { makeId } from "../../../nanoid";
import { reportKindDefaultOption, ReportKindValues } from "../../../ReportKind";
import { reportSectionsDefaultOptions } from "../../../ReportSections";
import { prepareBatchRequest } from "../PrepareBatchRequest";

export const BatchRnaWizard = (): JSX.Element => {
  const methods = useForm<BatchRnaFormInputs>({
    defaultValues: {
      file: [],
      configuration: rnaConfigurationLatestOption,
      reportKind: reportKindDefaultOption,
      drugCount: drugCountDefaultOption,
      reportSections: reportSectionsDefaultOptions,
    },
  });

  //const [expectedDuration, setExpectedDuration] = useState(30 * 60);
  const [requestsId, setRequestsId] = useState([] as string[]);
  const [reportKind, setReportKind] = useState(reportKindDefaultOption.value);

  const [batchId, setBatchId] = useState("");
  const { msg, clearMessage, setMessage, appendMessage, setError } =
    useWizardMessage();

  const handleReset = () => {
    methods.reset();
    clearMessage();
  };

  const postRequest = async (
    batchId: string,
    fef: ReportFile,
    configId: RnaConfigurationsId,
    patientInfo: RequestPatientInfo,
    requestId: string,
    rnaReportKind: "xlsx" | "json" | "pdf",
    maxDrugs: number,
    reportSections: RnaReportSection[]
  ): Promise<string> => {
    try {
      maxDrugs = rnaReportKind === "pdf" ? 10 : maxDrugs;
      const input: RnaRequestInput = {
        batchId,
        inputData: {
          maxDrugs,
          rnaReportKind,
          reportSections,
          fef,
          configId,
        },
        patientInfo,
      };

      appendMessage(".");
      const postMsg = `posting request ${requestId}..`;
      console.log(postMsg);
      await postRnaReportRequest(requestId, input);
      return requestId;
    } catch (e) {
      console.log(e);
      return requestId;
    }
  };

  const getExpectedDuration = (
    reportKind: ReportKindValues,
    drugCount: number
  ): number => {
    switch (reportKind) {
      case "json":
        return 2 * 60;
      default:
        return 3 * 60;
    }
  };

  const drugCountValue = useWatch({
    name: "drugCount.value",
    control: methods.control,
  });
  const reportKindValue = useWatch({
    name: "reportKind.value",
    control: methods.control,
  });

  const expectedDuration = useMemo(() => {
    return getExpectedDuration(reportKindValue, drugCountValue);
  }, [reportKindValue, drugCountValue]);

  const handleSubmit = async (data: BatchRnaFormInputs): Promise<void> => {
    try {
      clearMessage();
      setRequestsId([]);
      setBatchId("");

      const batchId = makeId();
      setMessage(`Uploading file(s)`, `Batch Id ${batchId}`);
      const preparedRequest = await prepareBatchRequest(
        batchId,
        data.file,
        appendMessage,
        undefined, // location
        undefined // physician
      );
      const { fef, patientInfo, requestIds } = preparedRequest;
      const configId = data.configuration.value;
      setMessage(
        `Posting ${requestIds.length} request(s)`,
        `Batch Id ${batchId}`
      );
      const reportSections = data.reportSections.map((x) => x.value);
      const postPromises = requestIds.map(
        (requestId: string, index: number) => {
          const file = fef[index];
          const rnaReportKind = data.reportKind.value;
          const maxDrugs = data.drugCount.value;
          return postRequest(
            batchId,
            file,
            configId,
            patientInfo,
            requestId,
            rnaReportKind,
            maxDrugs,
            reportSections
          );
        }
      );
      const posted = await Promise.all(postPromises);
      setMessage(
        `Posted ${requestIds.length} request${
          requestIds.length > 1 ? "s" : ""
        }`,
        `Batch Id ${batchId}`
      );
      setRequestsId(posted);
      setBatchId(batchId);
    } catch (e: any) {
      setError(e.toString(), "Error generating reports", "");
      console.log(e);
    }
  };

  methods.watch((data) => {
    if (data) {
      const { reportKind } = data;
      if (reportKind) {
        setReportKind(reportKind.value);
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <BatchWizardContainer<BatchRnaFormInputs>
        expectedDuration={expectedDuration}
        onReset={() => handleReset()}
        onSubmit={(x: BatchRnaFormInputs) => handleSubmit(x)}
        requestIds={requestsId}
        batchId={batchId}
        title="Batch RNA Report"
        fileLabel="zip"
        msg={msg}
      >
        ExpectedDuration {expectedDuration}
        <Row>
          <Col>
            <ConfigurationSelector options={rnaConfigurationsOptions} />
          </Col>
          <Col>
            <ReportKindSelector />
          </Col>
          <Col>
            {reportKindValue !== "pdf" && (
              <DrugCountSelector reportKind={reportKind} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>{reportKindValue !== "pdf" && <ReportSectionsSelector />}</Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </BatchWizardContainer>
    </FormProvider>
  );
};
