import "./BatchFileLink.css";

import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ArchiveFormat } from "@arianapharma/oncokem-services";
import { getSignedUrl, archive } from "../../API";

export interface BatchFileLinkProps {
  batchId: string;
  visible: boolean;
  disabled: boolean;
  filename: string;
  archiveKind: ArchiveFormat;
  children: React.ReactNode;
}

type BatchFileLinkStep = "idle" | "error" | "archiving" | "downloading";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export default function BatchFileLink(props: BatchFileLinkProps) {
  const [step, setStep] = useState<BatchFileLinkStep>("idle");
  const [signed, setSigned] = useState("");

  const loopGetSigned = async (key: string): Promise<string> => {
    let attempt = 0;
    while (attempt < 100) {
      attempt = attempt + 1;
      await delay(5000);
      try {
        const url = await getSignedUrl(key);
        console.log(`Got ${url} after ${attempt} attempts`);
        return url;
      } catch (e: any) {
        console.log(e);
      }
    }
    throw new Error("Too many attempts");
  };
  const handleClick = async (): Promise<void> => {
    try {
      setSigned("");
      setStep("archiving");
      const batchId = props.batchId;
      const format = props.archiveKind;
      console.log("archiving batchId", batchId);
      const now = new Date().getTime().toString();
      const key = `archives/batch-${batchId}-${now}.${format}`;
      try {
        await archive(batchId, props.archiveKind, now);
      } catch (e: any) {
        console.log(e);
      }
      const url = await loopGetSigned(key);
      console.log(url);
      // setStep("downloading");
      // const filename = `batch-${props.batchId}.${props.archiveKind}`;
      // FileSaver.saveAs(url, filename);
      setStep("idle");
      setSigned(url);
    } catch (e) {
      setSigned("");
      setStep("error");
      console.log("API files get error", e);
    }
  };

  const isRunning = step === "downloading" || step === "archiving";
  const spinnerVariant = (): string => {
    switch (step) {
      case "archiving":
        return "secondary";
      case "downloading":
        return "success";
      default:
        return "primary";
    }
  };
  return (
    <div>
      <Button disabled={isRunning} onClick={handleClick}>
        <div>
          {isRunning && (
            <Spinner
              variant={spinnerVariant()}
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          {props.children}
        </div>
      </Button>{" "}
      {signed && (
        <a href={signed} download>
          Click to download
        </a>
      )}{" "}
    </div>
  );
}
