import { Alert } from 'react-bootstrap';

import { WizardMessage } from './WizardMessage';

export interface WizardMessageAlertProps {
  msg?: WizardMessage;
}
export const WizardMessageAlert = (props: WizardMessageAlertProps) => {
  const msg = props.msg;
  if (!msg) {
    return <></>;
  }

  const { variant, title, main, bottom } = msg;

  return (
    <Alert variant={variant}>
      <Alert.Heading>{title}</Alert.Heading>
      <hr />
      <p>{main}</p>
      <div hidden={!bottom}>
        <hr />
        <p className="mb-0">{bottom}</p>
      </div>
    </Alert>
  );
};
