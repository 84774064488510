import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';

import { DevTool } from '@hookform/devtools';

import { DropFefZone } from '../../components/DropFefZone/DropFefZone';
import { FormInputs } from '../../interfaces/FormInputs';
import { RequestProgress } from '../RequestProgress/RequestProgress';
import { Title } from '../Title/Title';
import { WizardMessage } from '../WizardMessage/WizardMessage';
import { WizardMessageAlert } from '../WizardMessage/WizardMessageAlert';

export interface WizardContainerProps<T> {
  onReset: () => void;
  onSubmit: SubmitHandler<T>;
  fileLabel: "XLSX" | "PDF" | "JSON";
  title: string;
  requestId: string;
  msg?: WizardMessage;
  children: React.ReactNode;
}

export const WizardContainer = <T extends FormInputs>(
  props: WizardContainerProps<T>
) => {
  const [key, setKey] = useState(0);
  const methods = useFormContext();
  console.log({ methods });
  const { handleSubmit, control } = methods;

  const { onSubmit, onReset } = props;

  const handleReset = () => {
    onReset();
    setKey(key + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <DevTool control={control} placement={"top-right"} />
        <Row>
          <Title>{props.title}</Title>
        </Row>
        <Row hidden={!props.msg}>
          <Col>
            <WizardMessageAlert msg={props.msg} />
          </Col>
        </Row>
        <Row>
          <Col>
            <RequestProgress
              key={props.requestId}
              requestId={props.requestId}
              fileLabel={props.fileLabel}
            />
          </Col>
        </Row>
        <Row>
          <Col>{props.children}</Col>
        </Row>
        <Row>
          <Col>
            <Controller
              name="file"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <DropFefZone key={key} onChange={onChange} />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit">Generate</Button>
          </Col>
          <Col>
            <Button onClick={() => handleReset()}>Reset Form</Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};
