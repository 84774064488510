import { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

export async function s3Upload(file: any): Promise<string> {
  if (!file) {
    throw new Error("S3 upload: Missing file");
  }
  console.log("file", file);
  const uuid: string = uuidv4();
  const filename = `${uuid}-${file.name}`;
  const stored = await Storage.vault.put(filename, file, {
    contentType: "text/plain",
  });

  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser({
    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  });

  console.log("currentAuthenticatedUser", currentAuthenticatedUser);
  const currentSession = await Auth.currentSession();
  console.log(currentSession);
  console.log("stored", stored);
  // @ts-ignore
  return stored.key;
}
