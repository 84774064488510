import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';

import { PatientInfo, WizardContainer } from '../../../components';
import { DnaFormInputs } from '../../../interfaces';

export const DnaWizard = () => {
  const methods = useForm<DnaFormInputs>();
  const handleSubmit = (data: DnaFormInputs) => console.log(data);
  const handleReset = () => methods.reset();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requestId, setRequestId] = useState("");

  return (
    <FormProvider {...methods}>
      <WizardContainer
        onReset={handleReset}
        onSubmit={handleSubmit}
        requestId={requestId}
        title="DNA Report"
        fileLabel="PDF"
      >
        <Row>
          <Col>
            <PatientInfo />
          </Col>
        </Row>
      </WizardContainer>
    </FormProvider>
  );
};
