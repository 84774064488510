import { v4 as uuidv4 } from "uuid";
import { BatchPatientInfo } from "../../BatchPatientInfo";
import { s3Upload } from "../../libs/awsLib";

export interface PreparedBatchRequest {
  requestIds: string[];
  batchId: string;
  patientInfo: BatchPatientInfo;
  fef: {
    key: string;
    bucket: string;
  }[];
}
export const prepareBatchRequest = async (
  batchId: string,
  files: File[],
  appendMessage: (msg: string) => void,
  location?: string,
  physician?: string
): Promise<PreparedBatchRequest> => {
  if (!files || files.length === 0) {
    const msg = "Missing FEF file(s)";
    alert(msg);
    throw new Error(msg);
  }
  const uploadPromises = files.map((x) => {
    appendMessage(".");
    return s3Upload(x);
  });
  const requestIds = files.map(() => uuidv4());
  console.log("uploading..");
  const keys = await Promise.all(uploadPromises);
  console.log("uploaded");
  const bucket = "";
  const fef = keys.map((key) => ({ key, bucket }));
  const reportDate = new Date().toDateString().split(" ").slice(1).join(" ");
  const result: PreparedBatchRequest = {
    batchId,
    requestIds,
    fef,
    patientInfo: {
      reportDate,
      location: location,
      physician: physician,
    },
  };
  return result;
};
