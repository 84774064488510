//  Number.MAX_SAFE_INTEGER 9007199254740991
export type DrugCountValues = 1 | 10 | 20 | 100 | 9007199254740991;

export interface DrugCountOption {
  value: DrugCountValues;
  label: string;
}

export const drugCountDefaultOption: DrugCountOption = {
  value: 10,
  label: "10",
};

export const drugCountOptions: DrugCountOption[] = [
  {
    value: 1,
    label: "1 (useful for debugging)",
  },
  drugCountDefaultOption,
  {
    value: 20,
    label: "20",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 9007199254740991,
    label: "All",
  },
];
