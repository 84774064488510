import React, { useState } from "react";
import { Alert } from "react-bootstrap";

import {
  ArchiveFormat,
  UpdateRequestInfo,
} from "@arianapharma/oncokem-services";

import BatchFileLink from "../BatchFileLink/BatchFileLink";
import { Timer } from "../Timer/Timer";
import { useInterval } from "./useInterval";
import { getRequestInfo } from "../../API";

export interface BatchRequestProgressProps {
  batchId: string;
  requestIds: string[];
  fileLabel: ArchiveFormat;
  expectedDuration: number;
}

type AlertVariant = "primary" | "danger" | "warning" | "success" | "secondary";

interface RequestValues {
  requestValues?: unknown;
  error?: string;
  reportKey?: string;
}

interface Progress {
  alertVariant: AlertVariant;
  isLoading: boolean;
  requestValues: RequestValues[];
}

const getIsLoading = (requestStatuses = [] as string[]): boolean => {
  const started = requestStatuses.some((x) => x === "started");
  const inProgress = requestStatuses.some((x) => x === "in-progress");
  return started || inProgress;
};

const getVariant = (requestStatuses = [] as string[]): AlertVariant => {
  const error = requestStatuses.some((x) => x === "error");
  if (error) {
    return "danger";
  }

  const done = requestStatuses.every((x) => x === "done");
  if (done) {
    return "success";
  }

  const inProgress = requestStatuses.every((x) => x === "in-progress");
  const started = requestStatuses.every((x) => x === "started");

  if (inProgress || started) {
    return "primary";
  }
  return "warning";
};

const requestValues = (info: UpdateRequestInfo[]): RequestValues[] => {
  return info.map((x) => {
    if (!x.requestValues) {
      return {};
    }
    return JSON.parse(x.requestValues);
  });
};

const getRequestInfos = async (
  requestIds: string[]
): Promise<UpdateRequestInfo[]> => {
  const requests: Promise<UpdateRequestInfo>[] = requestIds.map(getRequestInfo);
  const result = Promise.all(requests);
  return result;
};

const getProgress = (info: UpdateRequestInfo[]): Progress => {
  const requestStatuses = info.map((x) => x.requestStatus);
  return {
    isLoading: getIsLoading(requestStatuses),
    alertVariant: getVariant(requestStatuses),
    requestValues: requestValues(info),
  };
};

export const BatchRequestProgress = (props: BatchRequestProgressProps) => {
  const defaultInfo: UpdateRequestInfo[] = props.requestIds.map(
    (requestId) => ({
      requestId,
      requestStatus: "started",
    })
  );

  const defaultProgress: Progress = {
    alertVariant: "primary",
    isLoading: true,
    requestValues: [],
  };

  const [info, setInfo] = useState<UpdateRequestInfo[]>(defaultInfo);
  const [progress, setProgress] = useState<Progress>(defaultProgress);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [delay, _setDelay] = useState(1000);

  useInterval(
    async () => {
      if (props.requestIds) {
        const info: UpdateRequestInfo[] = await getRequestInfos(
          props.requestIds
        );
        const progress: Progress = getProgress(info);
        setInfo(info);
        setProgress(progress);
        console.log("info", info);
        console.log("progress", progress);
      }
    },
    progress.isLoading ? delay : null
  );

  const liCount = (key: string, status: string, count: Map<string, number>) => {
    const value = count.has(status) ? count.get(status) || 0 : 0;
    if (value > 0) {
      return (
        <li key={key}>
          {key}: {requestCountText(value)}
        </li>
      );
    }
    return <></>;
  };

  const requestStatusCount = (info: UpdateRequestInfo[]) => {
    const counts = new Map<string, number>();
    for (let i = 0; i < info.length; i++) {
      const requestInfo = info[i];
      const current = counts.get(requestInfo.requestStatus) || 0;
      counts.set(requestInfo.requestStatus, current + 1);
    }
    return (
      <>
        {liCount("started", "started", counts)}
        {liCount("in-progress", "in-progress", counts)}
        {liCount("error", "error", counts)}
        {liCount("done", "done", counts)}
      </>
    );
  };

  const requestStepCount = (info: UpdateRequestInfo[]) => {
    const counts = new Map<string, number>();
    for (let i = 0; i < info.length; i++) {
      const requestInfo = info[i];
      const step = requestInfo.requestStep || "-";
      const current = counts.get(step) || 0;
      counts.set(step, current + 1);
    }
    const keys = Array.from(counts.keys());
    return <>{keys.map((x) => liCount(`step-${x}`, x, counts))}</>;
  };

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const reportKeys = (progress: Progress): string[] => {
    const keys = progress.requestValues.map((x) => x.reportKey);
    return keys.filter(notEmpty);
  };

  const complete = (progress: Progress): boolean => {
    return reportKeys(progress).length === props.requestIds.length;
  };

  const errorEntries = (progress: Progress) => {
    const errors = progress.requestValues.map((x) => x.error);
    const nonNullErrors: string[] = errors.filter(notEmpty);
    return (
      <>
        {nonNullErrors.map((x: string) => (
          <li>{x}</li>
        ))}
      </>
    );
  };

  const requestCountText = (count: number): string => {
    const plural = count > 1 ? "s" : "";
    return `${count} request${plural}`;
  };

  const getFilename = (fileLabel: ArchiveFormat): string => {
    switch (props.fileLabel) {
      case "zip":
        return `batch-${props.batchId}.zip`;
      case "tar":
        return `batch-${props.batchId}.tar`;
      //case "gzip":
      //  return `batch-${props.batchId}.tar.gzip`;
      default:
        return "";
    }
  };

  const isComplete: boolean = complete(progress);

  const renderTaskProgress = () => {
    const totalCount = props.requestIds.length;
    return (
      <div>
        {props.requestIds && props.requestIds.length > 0 && (
          <Alert key={props.batchId} variant={progress.alertVariant}>
            <Alert.Heading>
              Batch Id {props.batchId} - {requestCountText(totalCount)}
            </Alert.Heading>

            <hr />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 2 }}>
                <p className="mb-0">
                  <ul>
                    {requestStatusCount(info)}
                    {requestStepCount(info)}
                    {errorEntries(progress)}
                  </ul>
                </p>

                {isComplete && (
                  <BatchFileLink
                    archiveKind={props.fileLabel}
                    filename={getFilename(props.fileLabel)}
                    batchId={props.batchId}
                    visible={true}
                    disabled={false}
                  >
                    [{props.fileLabel}]
                  </BatchFileLink>
                )}
              </div>
              {!isComplete && (
                <div style={{ flex: 1 }}>
                  <Timer key={props.batchId} duration={60 * 3}></Timer>
                </div>
              )}
            </div>
          </Alert>
        )}
      </div>
    );
  };

  return (
    <div key={props.batchId}>
      {props.requestIds.length > 0 && (
        <>
          <div>{renderTaskProgress()}</div>
        </>
      )}
    </div>
  );
};
