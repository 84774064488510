// import { useHistory } from "react-router-dom";
import './Login.css';

import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';

import { LoaderButton } from '../../components';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import { useFormFields } from '../../libs/hooksLib';

// https://github.com/AnomalyInnovations/serverless-stack-demo-client

export default function Login() {
  // const history = useHistory();

  // @ts-ignore
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      // history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}
