import './LoaderButton.css';

import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

// @ts-ignore
export default function LoaderButton({
                                         isLoading = false,
                                         className = "",
                                         disabled = false,
                                         ...props
                                     }) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {
                isLoading &&
                <Spinner animation="border" size="sm" role="status">
                    <span className="sr-only"> Loading...</span>
                </Spinner>
            }
            {props.children}
        </Button>
    );
}
