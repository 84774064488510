import './GenePanels.css';

import { useEffect, useMemo } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { GenePanelValidationStatus } from '@arianapharma/oncokem-services';

import { FadeInDiv5Sec } from '../../Animations';
import { checkGenePanels } from '../../API';
import { Table, TableColumn } from '../../components';
import { fetchGenePanels, selectGlobal } from '../../slices/GlobalSlice';

interface GenePanelsTableRow extends GenePanelValidationStatus {
  id: string;
}

type GenePanelsTableColumn = TableColumn<GenePanelsTableRow>;

interface GenePanelsTableData {
  columns: Array<GenePanelsTableColumn>;
  data: GenePanelsTableRow[];
}

export default function GenePanelsStatus() {
  const dispatch = useDispatch();
  const globalState = useSelector(selectGlobal);

  // { isLoading, error, data, isFetching }
  const checkQuery = useQuery("checkGenePanels", checkGenePanels, {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const data: GenePanelsTableRow[] =
    globalState.genePanels.map<GenePanelsTableRow>((x) => ({
      id: x.bucket + x.key + x.versionId,
      ...x,
    }));

  const columns: Array<GenePanelsTableColumn> = useMemo(
    () => [
      {
        Header: "Bucket",
        accessor: "bucket",
      },
      {
        Header: "Key",
        accessor: "key",
      },
      {
        Header: "Version Id",
        accessor: "versionId",
      },
      {
        Header: "Is Valid",
        accessor: (x) => String(x.valid),
      },
      {
        Header: "Validation Error",
        accessor: "validation",
      },
      {
        Header: "Last Modified On",
        accessor: "lastModified",
        Cell: (props: any) => {
          const value: string =
            props && props.value ? new Date(props.value).toDateString() : "";
          return <span>{value}</span>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchGenePanels());
  }, [dispatch]);

  const tableData: GenePanelsTableData = {
    columns,
    data,
  };

  console.table(tableData);
  const handleCheck = async () => {
    checkQuery.refetch();
  };
  return (
    <FadeInDiv5Sec>
      <div className="GenePanels" style={{ padding: "10px", margin: "10px" }}>
        <Container>
          <Row>
            <Col>
              <Button
                disabled={checkQuery.isFetching}
                onClick={() => handleCheck()}
              >
                <span>
                  Re-Check{" "}
                  {checkQuery.isFetching ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    ""
                  )}
                </span>
              </Button>

              <p>{checkQuery.data}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table columns={tableData.columns} data={tableData.data} />
            </Col>
          </Row>
        </Container>
      </div>
    </FadeInDiv5Sec>
  );
}
