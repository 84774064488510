import React from 'react';
import { Col, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { RequestPatientInfo } from '@arianapharma/oncokem-services';

import { PatientId } from '../../PatientId';

export const PatientInfo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RequestPatientInfo & PatientId>();

  return (
    <div>
      <Row className="mt-3">
        <Col>
          <FormGroup controlId="patientId">
            <FormLabel>Patient Id</FormLabel>
            <FormControl
              className={errors.patientId ? "is-invalid" : "valid"}
              {...register("patientId", {
                required: true,
                valueAsNumber: true,
                min: 1,
              })}
              type="number"
              required
            />

            <Form.Control.Feedback type="invalid">
              Patient ID must be defined and greater than 0
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="physician">
            <FormLabel>Physician</FormLabel>
            <FormControl
              placeholder="Dr. Smith"
              {...register("physician")}
              type="string"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="location">
            <FormLabel>Location</FormLabel>
            <FormControl
              placeholder="Hospital ABC"
              {...register("location")}
              type="string"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormGroup controlId="histology">
            <FormLabel>Histology</FormLabel>
            <FormControl {...register("histology")} type="string" />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="tumorSite">
            <FormLabel>Tumor Site</FormLabel>
            <FormControl {...register("tumorSite")} type="string" />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="metastasis">
            <FormLabel>Metastasis</FormLabel>
            <FormControl {...register("metastasis")} type="string" />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormGroup controlId="sex">
            <FormLabel>Sex</FormLabel>
            <FormControl {...register("sex")} type="string" />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup controlId="age">
            <FormLabel>Age</FormLabel>
            <FormControl
              className={errors.age ? "is-invalid" : "valid"}
              {...register("age", {
                required: false,
                valueAsNumber: true,
                min: 0,
              })}
              type="number"
            />

            <Form.Control.Feedback type="invalid">
              Patient age must be greater or equal to 0
            </Form.Control.Feedback>
          </FormGroup>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};
