import { createContext, useContext } from 'react';

type ContextProps = {
  isAuthenticated: boolean;
  userHasAuthenticated: any;
};

export const AppContext = createContext<Partial<ContextProps>>({});

export function useAppContext() {
  return useContext(AppContext);
}
