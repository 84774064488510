import './Home.css';

import React from 'react';

import { FadeInDiv3Sec } from '../../Animations';

export default function Home() {
  return (
    <div className="Home">
      <div className="lander">
        <FadeInDiv3Sec>
          <h1>OncoKEM</h1>
          <div>
            <p>
              ({process.env.REACT_APP_STAGE}, {process.env.REACT_APP_DATE})
            </p>
          </div>
        </FadeInDiv3Sec>
      </div>
    </div>
  );
}
