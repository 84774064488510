import { pick } from "lodash";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Col, Row } from "react-bootstrap";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";

import { ComposerService } from "@arianapharma/oncokem-composer";
import {
  GenePanel,
  GenePanelComposition,
  GenePanelSingleton,
  GenePanelValidationStatus,
  GenePanelsRequestInput,
  RequestPatientInfo,
} from "@arianapharma/oncokem-services";

import { fetchGenePanel, postGenesReport } from "../../../API";
import {
  GenePanelChart,
  GenePanelSelector,
  PatientInfo,
  WizardContainer,
  useWizardMessage,
} from "../../../components";
import { ChartParam, GenePanelsFormInputs } from "../../../interfaces";
import { prepareRequest } from "../PrepareRequest";

export const GenePanelsWizard = () => {
  const methods = useForm<GenePanelsFormInputs>({
    defaultValues: {
      file: [],
      charts: [],
    },
  });
  const {
    control,
    register,
    // formState: { isDirty },
  } = methods;
  // dnd
  // https://codesandbox.io/s/react-hook-form-usefieldarray-kit8r?file=/src/index.js
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/simple/simple.jsx
  const chartsFieldArray = useFieldArray({ control, name: "charts" });

  const handleReset = () => methods.reset();

  const handleSubmit = async (
    genePanelsFormInputs: GenePanelsFormInputs
  ): Promise<void> => {
    console.log("onSubmit", { onSubmit: genePanelsFormInputs });
    try {
      const { patientId, file } = genePanelsFormInputs;

      const patientInfo: RequestPatientInfo = pick(genePanelsFormInputs, [
        "sex",
        "metastasis",
        "location",
        "physician",
        "tumorSite",
        "age",
        "histology",
      ]);
      const preparedRequest = await prepareRequest(
        patientId,
        file,
        patientInfo
      );
      const genePanelParams = genePanelsFormInputs.charts.map(
        (x: ChartParam) => {
          const composition = JSON.parse(x.composition) as GenePanelComposition;
          return {
            ...x,
            composition,
          };
        }
      );
      const {
        fef,
        patientInfo: patientInfoPrepared,
        requestId,
      } = preparedRequest;
      const input: GenePanelsRequestInput = {
        inputData: {
          fef,
          genePanelParams,
        },
        patientInfo: patientInfoPrepared,
      };
      setRequestId(requestId);
      return postGenesReport(requestId, input);
    } catch (e: any) {
      setError(e.toString(), "Error Generating Gene Panels Report");
    }
  };

  const [requestId, setRequestId] = useState("");
  const { msg, setMessage, setError, appendMessage } = useWizardMessage();
  const [showAddGenePanels, setShowAddGenePanels] = useState(false);

  const handleClose = () => setShowAddGenePanels(false);
  const handleShow = () => setShowAddGenePanels(true);

  const handleRemoveSelected = () => chartsFieldArray.remove(selectedIndexes());

  /*
  const handleSelectedAll = (selected: boolean) => {
    const setSelected = (chart: ChartParam, index: number) => {
      const name = `charts.${index}.selected` as const;
      const field: `charts.${number}.selected` = name;
      methods.setValue(field, selected);
    };
    methods.watch("charts").forEach(setSelected);
  };
  */

  const handleSelectedAll = (selected: boolean) => {
    console.log({ selected });
    let charts: ChartParam[] = methods
      .getValues<"charts">("charts")
      .map((x) => {
        return { ...x, selected, id: v4() };
      });
    console.log({ charts });
    methods.setValue("charts", charts, { shouldDirty: true });
  };

  /*
  methods.watch((data, other) => {
    console.log({ data, other });
  });
*/
  const selectedIndexes = (): number[] =>
    methods
      .watch("charts")
      .map((c: ChartParam, i: number) => ({ c, i }))
      .filter((x) => !!x.c.selected)
      .map((x) => x.i);

  const selectedCount = () => selectedIndexes().length;

  const addChartPanels = async (genePanels: GenePanelValidationStatus[]) => {
    try {
      handleClose();
      const plural = genePanels.length > 1 ? "s" : "";
      setMessage(
        `Fetching ${genePanels.length} gene panel${plural}...`,
        `Chart${plural}`
      );
      const fetchPromises = genePanels.map((x) =>
        fetchGenePanel(x.key, x.versionId)
      );
      const fetched: GenePanel[] = await Promise.all(fetchPromises);
      const composer = new ComposerService();
      const charts: ChartParam[] = fetched.map((genePanel: GenePanel) => {
        const singleton: GenePanelSingleton = {
          kind: "singleton",
          genePanel,
        };
        const symbols = composer.compositionUniqueSymbols(singleton);
        const symbolsCount = symbols.length;
        return {
          name: genePanel.name,
          comments: genePanel.comments,
          description: genePanel.description,
          id: v4(),
          top: 15,
          symbolsCount,
          composition: JSON.stringify(singleton),
          log2: true,
          genePanel,
          selected: false,
        };
      });
      chartsFieldArray.append(charts);
      appendMessage(`Appended ${genePanels.length} chart${plural}.`);
    } catch (e: any) {
      setError(e.toString());
    }
  };

  const handleDrag = ({ source, destination }: any) => {
    if (destination) {
      chartsFieldArray.move(source.index, destination.index);
    }
  };

  const renderchartsFieldArray = () => {
    return chartsFieldArray.fields.map((item, index) => {
      const id = item.id;
      return (
        <Draggable key={`chart-${id}`} draggableId={`item-${id}`} index={index}>
          {(provided: any, snapshot: any) => (
            <div
              key={item.id}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Row>
                <Col>
                  <GenePanelChart
                    dragHandleProps={provided.dragHandleProps}
                    key={item.id}
                    index={index}
                    chart={item}
                    register={register}
                    removeChart={(x) => chartsFieldArray.remove(index)}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Draggable>
      );
    });
  };

  return (
    <FormProvider {...methods}>
      <WizardContainer
        msg={msg}
        fileLabel="PDF"
        requestId={requestId}
        title="Gene Panels"
        onReset={() => handleReset()}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col>{selectedCount()} Selected count</Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <PatientInfo />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="primary" onClick={() => handleShow()}>
              Add Panels
            </Button>
            {"  "}
            <Button variant="primary" onClick={() => handleRemoveSelected()}>
              Remove selected
            </Button>
            {"  "}
            <Button variant="primary" onClick={() => handleSelectedAll(true)}>
              Select all
            </Button>
            {"  "}
            <Button variant="primary" onClick={() => handleSelectedAll(false)}>
              Unselect all
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <GenePanelSelector
              close={() => handleClose()}
              show={showAddGenePanels}
              selectedGenePanels={(x) => addChartPanels(x)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {chartsFieldArray.fields.length > 0 && (
              <DragDropContext onDragEnd={handleDrag}>
                <Row>
                  <Col>
                    <Droppable droppableId="test-items">
                      {(provided: any, snapshot: any) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {renderchartsFieldArray()}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                </Row>
              </DragDropContext>
            )}
          </Col>
        </Row>
      </WizardContainer>
    </FormProvider>
  );
};
